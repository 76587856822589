<template>
  <div>
    <div class="pageMain">
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        label-position="left"
        class="demo-form-inline"
      >
      
        <el-form-item label="单据日期：">
          <el-date-picker @change="changedatepicker($event)" :default-time="['00:00:00','23:59:59']" value-format="yyyy-MM-dd HH:mm:ss" v-model="datePicker" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="商品名称：">
          <el-input
            v-model="searchForm.goodsName"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="供应商：">
          <el-input
            v-model="searchForm.buyer"
            placeholder="请输入供应商"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="searchSubmit('searchForm')"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">采购单明细</p>

        <template>
            <div class="tableTopBtn">
            <el-button size="mini" type="primary" @click="exportData" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出</el-button>

          </div>
          <!-- :lazy="true"
              :load="lazyLoad" -->
          <el-table
            ref="lazyTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            v-loading="loading"
            height="45vh"
          >
            <el-table-column prop="created" label="采购日期"></el-table-column>
            <el-table-column prop="purchaseOrderId" label="单据编号" min-width="140">
              <template slot-scope="scope">
                <p class="numberId" @click="$router.push({ name: 'purchaseForm', params: { type: 'edit', id: scope.row.purchaseOrderId }})">{{scope.row.purchaseOrderId}}</p>
              </template>
            </el-table-column>
            <el-table-column prop="ywtype" label="业务类别" ></el-table-column>
            <el-table-column prop="buyer" label="采购员" ></el-table-column>
            <el-table-column prop="distributorName" label="供应商"></el-table-column>
            <!-- <el-table-column prop="code" label="商品编码" ></el-table-column> -->
            <el-table-column prop="goodsName" label="名称"> </el-table-column>
            <el-table-column prop="standard" label="规格型号"> </el-table-column>
            <el-table-column prop="unit" label="单位" ></el-table-column>
            <el-table-column prop="warehouseName" label="仓库" ></el-table-column>
            <el-table-column prop="num" label="数量" ></el-table-column>
            <el-table-column prop="price" label="单价" >
              <template slot-scope="scope">{{ scope.row.price }} 元</template>
            </el-table-column>
            <el-table-column prop="totalPrice" label="金额" >
              <template slot-scope="scope">{{ scope.row.totalPrice }} 元</template>
            </el-table-column>
            <el-table-column prop="memo" label="备注"></el-table-column>
            <el-table-column prop="created" label="创建时间">
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage}} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条；
            <el-select v-model="pagesData.currentRows" @change="currentRowsChange">
              <el-option v-for="(item,index) in pagesData.rows" :key="index" :value="item" :label="item+'条/页'"></el-option>
            </el-select>
            <!-- <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pagesData.currentPage"
              :page-sizes="pagesData.rows"
              :page-size="pagesData.currentRows"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pagesData.total"
            >
            </el-pagination> -->
          </div>
          <div class="totalNum">
            <div>合计：</div>
            <div><span>数量：{{numSum}}</span><span style="margin-left:50px">金额：{{priceSum}} 元</span></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
//vue实例
export default {
  data() {
    return {
      loading: true,
      //搜索
      datePicker: [],
      searchForm: {
        goodsName: "",
        buyer:"",
        startTime:"",
        endTime:"",
        goodsId:"",
      },
      tableData: [],
      tabWidth: 200,
      //分页数据
      pagesData: {
        pages:0,
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [100,1000, 5000, 10000, 100000],
      },
      exportButton:'1',
      numSum:'',
      priceSum:'',
      // 懒加载标识
      lazyFlag: true,
    };
  },
  created() {
    //this.listEvent();

    // var privilege = JSON.parse(sessionStorage.getItem("authority"));
    // privilege.forEach((item) => {
    //   if (item.authority == "sys_menu_query") {
    //     this.searchButton = "1";
    //   } else if (item.authority == "sys_menu_add") {
    //     this.exportButton = "1";
    //   }
    // });
  },
  mounted(){
    

    // 表格懒加载
    let table = this.$refs.lazyTable.$el;
    
    table.addEventListener('scroll',(res)=>{
      // console.log(res.target.scrollHeight, Number((res.target.scrollTop).toFixed(2)),res.target.clientHeight)
      if(Number((res.target.scrollTop).toFixed(2))+ res.target.clientHeight >= res.target.scrollHeight-20){
        if(this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages){
          this.$set(this.pagesData,'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    },true)
  },
  // watch: {
  //   // 如果路由有变化，会再次执行该方法
  //   $route: "listEvent",
  // },
  activated(){
    console.log(this.$route.params)
    let data = this.$route.params ? this.$route.params : this.$route.query;
    if(data.id){
      this.$set(this.searchForm, 'goodsId', data.id)
    }
    this.$set(this.pagesData, 'currentPage', 1)
    this.tableData = []
    this.listEvent();
  },
  methods: {
    // 分页设置
    currentRowsChange(e){
      this.$set(this.pagesData,'currentRows',e)
      this.$set(this.pagesData,'currentPage',1)
      this.listEvent()
    },
    changedatepicker(e) {
      console.log(e)
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent() {
      var _this = this;
      this.loading = true;
      //获取列表
      this.$axios
        .get(_this.$axios.defaults.basePath + "/purchaseOrder/detail", {
          params: {
            goodsName: _this.searchForm.goodsName,
            buyer: _this.searchForm.buyer,
            startTime: _this.searchForm.startTime,
            endTime: _this.searchForm.endTime,
            goodsId: _this.searchForm.goodsId,
            current: _this.pagesData.currentPage,
            size: _this.pagesData.currentRows,
          },
        })
        .then(function (res) {
          var resData = res.data;
          _this.loading = false;
          if (res.data.errcode == 0) {
            if(_this.tableData.length == 0){
              _this.tableData = resData.data.page.records;
            }else{
              _this.tableData = _this.tableData.concat(resData.data.page.records);
            }
            
            _this.pagesData.total = resData.data.page.total;
            _this.pagesData.pages = resData.data.page.pages;
            _this.numSum = resData.data.numSum;
            _this.priceSum = resData.data.priceSum;
            let arr = _this.tableData;
            arr.forEach((item, index) => {
              item.created = item.created.slice(0,10);
              item.ywtype = '采购';
            });
            if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
              _this.$message({
                message: '已经是最后一页了',
                type: 'info'
              })
            }
            _this.lazyFlag = true
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //侧边栏伸缩
    openAside() {
      if (this.isCollapse) {
        this.isCollapse = false;
        this.tabWidth = 200;
      } else {
        this.isCollapse = true;
        this.tabWidth = 64;
      }
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      var _this = this;
      _this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      var _this = this;
      _this.pagesData.currentPage = val;
      this.listEvent();
    },
    //分页请求数据方法
    pagesEvent(page, rows) {
      var _this = this;
      this.$axios
        .get(_this.$axios.defaults.basePath + "/purchaseOrder/detail", {
          params: {
            goodsName: _this.searchForm.goodsName,
            buyer: _this.searchForm.buyer,
            startTime: _this.searchForm.startTime,
            endTime: _this.searchForm.endTime,
            goodsId: _this.searchForm.goodsId,
            current: page,
            size: rows,
          },
        })
        .then(function (res) {
          var resData = res.data;
          _this.tableData = resData.data.page.records;
          _this.pagesData.total = resData.data.page.total;
          _this.priceSum = resData.data.priceSum;
        });
    },
    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.tableData = []
      this.listEvent();
    },
    //表单重置
    resetForm(formName) {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.pagesEvent(this.pagesData.currentPage, this.pagesData.currentRows);
      this.tableData = []
      this.listEvent();
      this.datePicker = [];
    },
    exportData() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/purchaseOrder/exportOrder", {
          params: {
            goodsName: _this.searchForm.goodsName,
            buyer: _this.searchForm.buyer,
            startTime: _this.searchForm.startTime,
            endTime: _this.searchForm.endTime,
            goodsId: _this.searchForm.goodsId,
          },
          responseType: 'blob'
        })
        .then((res) => {
          console.log(res)
            var eleLink = document.createElement("a");
            eleLink.download = "采购单明细.csv";
            eleLink.style.display = "none";

            var blob = new Blob([res.data]);
            eleLink.href = URL.createObjectURL(blob);
            console.info(blob);
            // 触发点击
            document.body.appendChild(eleLink);
            eleLink.click();
            _this.exportShow = false;
            _this.$message({
              message: "导出成功",
              type: "success",
            });
            _this.exportForm = {};
            // 然后移除
            document.body.removeChild(eleLink);
        })
      
    },
  },
};
</script>

<style scoped>
.totalNum{
    display: flex;
    justify-content: space-between;
    margin: 0px 50px 10px 20px;
    font-weight: 550;
}
</style>